.container
    margin-top: 170px

    display: flex
    flex-direction: column

    justify-content: center
    align-items: center
    width: 100vw


@media screen and ( max-width:  500px)
    .container
        margin-top: 100px
    