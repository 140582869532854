@media (max-width: 1300px)
    .container
        .slider
            
            .leftSide
                width: 700px
                height: 420px
                .mainImage
                
                    width: 700px
                    height: 415px
                    object-fit: cover

                .leftButton, .rightButton
                    height: 415px

                .rightButton
                    margin-left: 550px

                 

            .rightSide
                .moreThanTriple
                    width: 160px
                    height: 80px

                .tripleRide
                    width: 200px
                    height: 135px


        .lowContainer
            .inner
                width: 860px

                .leftWrapper


                .priceBlock
                    .price
                        font-size: 30px

                    button
                        width: 200px
                        height: 40px

                .parText
                    width: 400px
                    font-size: 16px

                .iconsBlock
                    width: 210px
                    img
                        width: 70px





@media (max-width: 900px) 
    .container
        margin-top: 100px
        .slider
            flex-direction: column
            align-items: center
            
            .leftSide
                height: 355px
                width: 705px

                .rightButton
                    margin-left: 605px

                .rightButton, .leftButton
                    height: 355px
                    width: 100px

                .mainImage
                    width: 705px
                    height: 355px
            .rightSide
                flex-direction: row
                .moreThanTriple
                    width: 140px
                    padding: 2px 2px 0 0

                .tripleRide
                    width: 235px

        .lowContainer
            .inner
                width: 705px
                flex-direction: column
                align-items: center
                justify-content: center
                
                .iconsBlock
                    width: 705px
                    flex-wrap: nowrap

                    margin-top: 20px
                    justify-content: center
                    img
                        width: 80px

                
@media (max-width: 715px) 
    .container
        .slider            
            .leftSide
                height: 355px
                width: 505px

                .rightButton
                    margin-left: 405px

                .rightButton, .leftButton
                    height: 355px
                    width: 100px

                .mainImage
                    width: 505px
                    height: 355px
            .rightSide
                flex-direction: row
                .moreThanTriple
                    width: 100px
                    padding: 2px 2px 0 0

                .tripleRide
                    width: 235px

        .lowContainer
            margin-top: 10px
            .inner
                width: 500px

                .leftWrapper
                    flex-direction: column
                    .priceBlock
                        align-items: center

                    .parText
                        width: 500px
                        margin-left: 0
                        margin-top: 30px
                        text-align: center
@media (max-width: 525px) 
    .container
        .slider
            
            .leftSide
                height: 205px
                width: 405px

                .rightButton
                    margin-left: 305px

                .rightButton, .leftButton
                    height: 205px
                    width: 100px

                .mainImage
                    width: 405px
                    height: 205px
            .rightSide
                flex-direction: row
                flex-wrap: wrap

                justify-content: center
                align-items: center
                width: 405px
                
                .moreThanTriple

                    width: 110px
                    height: 70px
                    padding: 2px 2px 0 0

                .tripleRide
                    width: 120px
                    height: 70px

        .lowContainer
            margin-top: 10px
            .inner
                width: 405px

                .leftWrapper
                    flex-direction: column
                    .priceBlock
                        align-items: center

                    .parText
                        width: 405px
                        margin-left: 0
                        margin-top: 30px
                        text-align: center

                .iconsBlock
                    width: 405px
                    img
                        width: 65px


@media (max-width: 425px) 
    .container
        .slider
            .leftSide
                height: 205px
                width: 360px

                .rightButton
                    margin-left: 310px

                .rightButton, .leftButton
                    height: 205px
                    width: 50px

                .mainImage
                    width: 360px
                    height: 205px
            .rightSide
                flex-direction: row
                flex-wrap: wrap

                justify-content: center
                align-items: center
                width: 405px
                
                .moreThanTriple

                    width: 110px
                    height: 70px
                    padding: 2px 2px 0 0

                .tripleRide
                    width: 120px
                    height: 70px


        .lowContainer
            .inner
                width: 360px

                .leftWrapper
                    flex-direction: column
                    .priceBlock
                        align-items: center

                    .parText
                        width: 360px
                        margin-left: 0
                        margin-top: 30px
                        text-align: center

                .iconsBlock
                    width: 360px
                    img
                        width: 55px



                