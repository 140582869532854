@keyframes toRight
  0%
    margin-right: 0vw
    opacity: 1

  99%
    margin-right: -50vw
    opacity: 0

  100%
    display: none

@keyframes toLeft
  0%
    margin-right: -50vw
    opacity: 0

  100%
    margin-right: 0vw
    opacity: 1

@font-face 
  font-family: 'MyFont'
  src: url("../../fonts/header.otf") format("truetype")

$my-font: 'MyFont', sans-serif
    
@media ( max-width: 1440px )
    .block
        .innerBlock
            width: 950px
            justify-content: space-between
            overflow: hidden
            
            .logo
                img
                    object-fit: cover
                    height: 77px
                    border-bottom: 3px
                .big
                    height: 90px
                    width: 100px 

                .small
                    width: 75px
                    height: 66.5px

                


            .navigation
                gap: 5%
                width: 710px
                
                .linkContainer
                    .link
                        font-size: 15px

                        
            .contacts
                width: 23%
                .phoneIcon
                    width: 16.5px

                .instIcon
                    width: 26px

                .telegramIcon
                    width: 22px
            

@media ( max-width: 1024px )
    .block
        width: 100vw
        .innerBlock
            width: 90%
            .navigation 
                display: none
                

            .logo
                z-index: 5
            .burgerMenu
                display: flex
                
                .opacity
                    animation: toLeft .4s
                    width: 50vw
                    height: 100vh

                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)
                    position: absolute

                    background: white

                    right: 0
                    top: 75px

                .nonOpacity
                    height: 100vh

                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)
                    opacity: 0.8
                    position: absolute



                    right: 0
                    top: 75px
                    animation: toRight .4s forwards
                    opacity: 0
                    background: white

                .navigation
                    display: flex
                    flex-direction: column

                    justify-content: center
                    align-items: flex-end

                    width: 100%
                    
                    gap: 15px

                    .link2
                        animation: toLeft .6s
                        text-decoration: none
                        color: black

                        text-align: right

                        white-space: nowrap
                         
                        font-size: 24px
                        font-family: $my-font

                        border-bottom: 1px solid #e8e8e8

                        width: 250px
                        padding-bottom: 19px
                        transition: .3s
                        
                        

                    .nonLink
                        animation: toRight .1s
                        
                        &:hover
                            color: coral
                            transition: .3s

                    .link:nth-child(7)
                        border: 0px
                        
                        

                img
                    width: 50px
                    transition: .5s
                    cursor: pointer

                .showedMenu
                    position: fixed
                    width: 45vw
                    height: 80vh
                    
                    right: 25px
                    top: 75px

                    display: flex
                    flex-direction: column

                    justify-content: center
                    align-items: flex-end


                    
                .hiddenMenu
                    display: none


            // .contacts
            //     margin-left: 50px
                
            //     .phoneNumber
            //         font-size: clamp(13px, 1.1vw, 35px)

            //     .phoneIcon
            //         width: 25px

            //     .instIcon
            //         width: 37px

            //     .telegramIcon
            //         width: 30px


@media ( max-width: 760px )

    .block .innerBlock .burgerMenu .navigation .link2
        font-size: 20px
        width: 180px
    

@media ( max-width: 560px ) 
    .block
        width: 100vw
        .innerBlock
            width: 95%
            .logo
                .big
                    width: 95px    

            .burgerMenu
                img
                    margin-right: 10px
                .opacity
                    width: 65vw
                .showedMenu
                    right: 20px
                    

@media ( max-width: 450px )
    .block
        // .innerBlock
        //     .contacts
        //         .phoneIcon
        //             display: none
        //         .instIcon
        //             width: 25px

        //         .telegramIcon
        //             width: 22px 


            .burgerMenu
                // img
                //     width: 30px


                .opacity
                    width: 100vw