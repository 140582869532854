@font-face 
    font-family: 'MyFont'
    src: url(../../fonts/header.otf) format('truetype')


$my-font: 'MyFont'
    
.container

    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    h1
        font-family: $my-font
        margin-top: 50px
        @media (max-width: 550px) 
            font-size: 20px

    .blocks
        width: 1350px
        display: flex
        flex-direction: row

        justify-content: center
        align-items: center  
        flex-wrap: wrap

        gap: 55px
        padding-bottom: 50px

        margin-left: -15px
        .block
            display: flex
            flex-direction: column

            justify-content: flex-end
            align-items: flex-start

            width: 630px
            height: 400px
            cursor: pointer
            overflow: hidden
            position: relative
            transition: .3s


            &:hover
                transition: .3s
                transform: translateY(-10px)
                
            .image
                object-fit: cover
                width: 100%
                height: 100%
                transition: transform .3s
                

                &:hover
                    transition: .3s
                    transform: scale(1.05)
            .name
                z-index: 5
                color: white
                margin: 0
                margin-left: 15px
                padding: 0
                position: absolute
                font-size: 25px
                font-family: $my-font
                transform: translateY(-10px)

@media (max-width: 1440px) 
    .container 
        .blocks
            width: 1150px
            .block
                width: 500px
                height: 320px

@media (max-width: 1170px)
    .container
        .blocks
            width: 870px
            .block
                width: 400px
                height: 220px

@media (max-width: 900px) 
    .container
        .blocks 
            width: 700px
            .block
                width: 300px
                height: 170px

@media (max-width: 723px) 
    .container
        .blocks 
            width: 540px
            .block
                width: 500px
                height: 270px

@media (max-width: 560px) 
    .container
        .blocks
            width: 400px
            .block
                width: 400px
                height: 220px


@media (max-width: 420px) 
    .container
        .blocks
            width: 340px
            .block
                width: 320px
                height: 180px

            

