@font-face 
    font-family: 'myFont'
    src: '../../fonts/header.otf' format('truetype')

$myFont: 'myFont'


    
.container
    width: 100vw
    height: 220px

    background-color: #212529

    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-bottom: 10px




    p
        line-height: 20px


    .innerContainer
        width: 1400px
        height: 340px
        display: flex
        flex-direction: row

        align-items: flex-start
        justify-content: space-between

        gap: 75px
        
        
        .left
            width: 130px
            height: 100%

            background: #91ce21
            display: flex
            flex-direction: column

            justify-content: flex-end
            align-items: center
            img
                width: 70%

        .right
            display: flex
            flex-direction: column

            justify-content: flex-start
            align-items: flex-end

            
            color: white

            font-family: $myFont
            height: 100%

            .socialMedias
                display: flex
                flex-direction: row

                justify-content: flex-end
                align-items: center
                gap: 5px
                width: 80%
                margin-top: 10px

                

                img
                    width: 40px
                    cursor: pointer
            
            p
                margin: 2px 0 0px 0
                font-size: 14px

                &:nth-child(1)
                    margin-top: 10px
@media (max-width: 1440px) 
    .container
        .innerContainer
            width: 970px
            .column
                h2
                    font-size: 21px

                .parTexts
                    .parText
                        font-size: 15px

@media (max-width: 1170px) 
    .container
        .innerContainer
            width: 700px

    

@media (max-width: 950px) 
    .container
        .innerContainer
            width: 600px
        



@media (max-width: 625px) 
    .container
        height: 200px
        .innerContainer
            width: 350px

            height: 100%


            .left
                width: 100px
                img
                    width: 50px
            .right

                p
                    font-size: 12px
                    white-space: nowrap
            
                .socialMedias
                    img
                        width: 25px
