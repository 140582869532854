@media (max-width: 1350px) 
    .container
        .middle
            .anotherBlocks
                width: 950px
                .anotherBlock
                    width: 300px
                    height: 300px

// @media (max-width: 1025px) 
//     .container
//         .anotherBlocks
//             height: 300px
//             .anotherBlock
//                 width: 250px
//                 height: 250px

@media (max-width: 1025px) 
    .container
        .mainText
            height: 120px
        .middle
            height: 2300px
            .anotherBlocks
                width: 700px
                flex-direction: column
                .anotherBlock
                    width: 700px
                    height: 700px

@media (max-width: 715px) 
    .container
        .middle
            height: 1800px
            .anotherBlocks
                width: 550px
                flex-direction: column
                .anotherBlock
                    width: 550px
                    height: 550px

@media (max-width: 575px) 
    .container
        .mainText
            height: 80px
        
        .middle
            
            height: 1500px
            .anotherBlocks
                width: 450px
                flex-direction: column
                .anotherBlock
                    width: 450px
                    height: 450px

@media (max-width: 460px) 
    .container

        .middle
            height: 1200px
            .anotherBlocks
                width: 350px
                height: 1200px
                flex-direction: column
                .anotherBlock
                    width: 350px
                    height: 350px

@media (max-width: 370px) 
    .container

        .middle
            height: 1200px
            .anotherBlocks
                width: 320px
                flex-direction: column
                .anotherBlock
                    width: 320px
                    height: 320px
