@keyframes up
  0%
    margin-top: 40px
    opacity: 0

  100%
    margin-top: 23px
    opacity: 1

@keyframes down
  0%
    margin-top: 23px
    opacity: 1

  100%
    margin-top: 40px
    opacity: 0

@font-face 
  font-family: 'MyFont'
  src: url("../../fonts/header.otf") format("truetype")

$my-font: 'MyFont', sans-serif

.block
  width: 100vw
  top: 0
  position: fixed
  z-index: 100
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15)

  display: flex
  flex-direction: column

  align-items: center
  justify-content: center
  
  background: white
  transition: .5s
  height: 75px


  .innerBlock
    width: 70%
    height: 100%
    display: flex
    flex-direction: row


    justify-content: space-between
    align-items: center

    
    

    .logo
      cursor: pointer
      .logoImage
        transition: .5s
        margin-top: -39px
        margin-left: -5px
        position: absolute
        border: 5px solid white
      .big
        width: 120px
      .small
        width: 75px

    .navigation
      display: flex
      flex-direction: row
      width: 800px
      gap: 5%

      
      .linkContainer
        
        .link
          white-space: nowrap
          
          text-decoration: none
          color: black
          padding: 35px 0     

          // font-size: clamp(12px, 0.9vw, 36px)
          font-weight: light
          font-family: $my-font

          color: #000000
          transition: .3s



          &:hover
            color: coral
        &:hover .subLinks
          display: flex
          margin-top: 23px
          animation: up .5s
          visibility: visible
          opacity: 1
        .subLinks
          opacity: 0
          position: absolute

          visibility: hidden

          display: flex
          flex-direction: column

          justify-content: center
          align-items: flex-start
          white-space: nowrap

          gap: 20px
          animation: down .5s

          transition: .5s
          background: white
          font-size: 20px



          .sublink
            font-size: 17px
            font-weight: light
            font-family: $my-font


            text-decoration: none
            color: black
            padding: 5px 50px 12px 13px
            transition: .3s

            &:hover
              color: coral
              transition: .3s
    // .contacts
    //   display: flex
    //   flex-direction: row

    //   justify-content: center
    //   align-items: center

    //   gap: 7px

    //   .phoneNumber
    //     color: black
    //     font-weight: bold
    //     white-space: nowrap

    //     font-size: clamp(12px, 0.9vw, 36px)
    //     font-family: $my-font
    //   .phoneIcon
    //     width: 20px
    //   .instIcon
    //     width: 30px
    //   .telegramIcon
    //     width: 25px

    .burgerMenu
      display: none