
@keyframes slideToLeft
    0%
        transform: translateX(-100%)
    100%
        transform: translateX(0)

@keyframes slideToRight
    0%
        transform: translateX(100%)
    100%
        transform: translateX(0%)

@font-face 
    font-family: 'MyFont'
    src: url('../../fonts/header.otf') format('truetype')

$my-font: 'MyFont'
    
.container
    margin-top: 150px
    overflow-x: hidden
    width: 100vw
    padding-bottom: 50px
    .slider
        display: flex
        flex-direction: row
        justify-content: center
        align-items: flex-start

        width: 100vw
        

        .leftSide
            display: flex
            height: 550px
            flex-direction: row
            overflow: hidden
            position: relative

            width: 1000px
            .leftButton, .rightButton
                display: flex
                flex-direction: column

                justify-content: center
                align-items: center
                cursor: pointer

                position: absolute
                background: none

                z-index: 50
                border: none
                width: 150px
                height: 550px
                transition: .3s

                &:hover
                    background: #424242
                    opacity: 0.5
                    transition: .3s
                img
                    width: 50px
                    height: 50px
                    object-fit: cover
                    opacity: 0.4
                    
                    
                    
            
            .rightButton
                background: none

                transform: scaleX(-1)
                margin-left: 850px
                
            .mainImage
                object-fit: cover
                height: 550px
                width: 1000px
                z-index: 0

            // .animatedLeft
            //     animation: slideToLeft .1s ease forwards

            // .animatedRight
            //     animation: slideToRight .1s ease forwards
                

        .rightSide
            display: flex
            z-index: 0
            
            flex-direction: column
            
            .moreThanTriple
                width: 250px  
                object-fit: cover
                height: 105px 
                cursor: pointer
                opacity: 0.5
                transition: .3s
                padding: 3px 0 0 3px

                &:hover
                    opacity: 0.8
                    transition: .3s

            .tripleRide
                object-fit: cover
                width: 250px
                height: 180px
                padding: 3px 0 0 3px
                cursor: pointer
                opacity: 0.5

                &:hover
                    opacity: 0.8
                    transition: .3s

            #light
                opacity: 0.8

    
    .lowContainer
        width: 100vw
        display: flex
        flex-direction: column

        justify-content: center
        align-items: center

        margin-top: 50px

        .inner
            display: flex
            flex-direction: row

            justify-content: space-between
            align-items: flex-start
            width: 1290px

            .leftWrapper
                display: flex
                flex-direction: row
            

            .priceBlock
                display: flex
                flex-direction: column

                justify-content: center
                align-items: flex-start

                .price
                    font-family: $my-font

                button
                    background-color: #960000
                    color: white

                    font-family: $my-font
                    width: 240px
                    height: 45px

                    border: none
                    font-size: 19px
                    cursor: pointer
                    transition: .3s

                    &:hover
                        border: 1px solid #960000
                        background: white
                        color: #960000
                        transition: .3s


            .parText
                width: 700px
                font-family: $my-font
                font-size: 19px
                margin-left: 50px

            .iconsBlock
                display: flex
                flex-direction: row

                flex-wrap: wrap
                width: 260px


                img
                    width: 85px