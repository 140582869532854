@mixin centerFlex
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

@font-face 
    font-family: 'MyFont'
    src: url('../../fonts/header.otf') format('truetype')

$my-font: 'MyFont', sans-serif
    
.container
    @include centerFlex
    
    margin-top: 50px
    background: #ebeff2

    .mainText
        margin: 0
        padding: 0
        display: flex
        flex-direction: column

        justify-content: center
        align-items: center
        margin-top: 20px
        font-family: $my-font

        width: 100vw
        height: 200px

    .middle
        width: 100vw
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        background-color: white
        height: 500px
        
        .anotherBlocks
            width: 1350px

            display: flex
            flex-direction: row

            justify-content: center
            align-items: center

            gap: 35px

            .anotherBlock
                display: flex
                flex-direction: column

                justify-content: center
                align-items: center

                transition: .3s
                cursor: pointer

                width: 420px
                height: 420px 

                overflow: hidden
                position: relative

                &:hover
                    transform: translateY(-10px)
                    transition: .3s
                    

                img
                    width: 100%
                    height: 100%
                    transition: .3s

                    &:hover
                        transform: scale(1.05)
                        transition: .3s
    

                h1
                    position: absolute
                    color: white
                    font-family: $my-font
                    font-size: 22px
                    text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5)


