@font-face 
    font-family: 'MyFont'
    src: url("../../fonts/header.otf") format('truetype')

$my-font: 'MyFont'
button
    width: 250px
    height: 55px

    background-color: #212529
    cursor: pointer 
    border: none
    color: white

    font-family: $my-font
    font-size: 17px
