@mixin centerFlex
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

@font-face 
    font-family: 'MyFont'
    src: url('../../fonts/header.otf') format('truetype')

$my-font: 'MyFont', sans-serif
    
.container
    @include centerFlex
    
    margin-top: 50px
    background: #ebeff2

    .mainText
        margin: 0
        padding: 0
        display: flex
        flex-direction: column

        justify-content: center
        align-items: center
        margin-top: 20px
        font-family: $my-font

        width: 100vw
        height: 200px

        p
            width: 90%
            text-align: center
            line-height: 30px



@media ( max-width:  500px)
    .container .mainText
        h1
            margin: 5px
            padding: 0
            font-size: 22px

        p
            margin: 5px
            padding: 0
            font-size: 14px
    